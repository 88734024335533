export type IFeatureFlags = {
  seeLogin: boolean;
  seeDashboard: boolean;
  seeOrders: boolean;
  createOrders: boolean;
  completeOrders: boolean;
  seeTransactionHistory: boolean;
  seeRotoplasCredit: boolean;
  seeMovementsTab: boolean;
  seeDevolution: boolean;
  seeCancelations: boolean;
  canCancelOrder: boolean;
  canReturnOrder: boolean;
  seeUserManagement: boolean;
  createUsers: boolean;
  haveBranches: boolean;
  seeShoppingLists: boolean;
  createShoppingLists: boolean;
  seeShoppingListsDetails: boolean;
  createShoppingListDetails: boolean;
  seeComunicados: boolean;
  seePLP: boolean;
  seeConfig: boolean;
  seeIncidences: boolean;
  seeEcommerceOrders: boolean;
  seeTransfer: boolean;
  seeCredit: boolean;
  seeCreditRotoplas: boolean; 
};

type Roles = Record<'superR'| 'Super Usuario asociado' | 'adminR' | 'PDVOperatorR' | 'PDVOperatorRA'  | 'customerR' | 'customerRA' | 'AliadoVABR' | any, IFeatureFlags>;

// type Roles = {
//     [key in 'superR'|'adminR'|'PDVOperatorR'|'customerR']: featureFlags;
// };

export const rol: Roles = {
  superR: {
    seeLogin: true,
    seeDashboard: true,
    seeOrders: true,
    createOrders: true,
    completeOrders: true,
    seeTransactionHistory: true,
    seeRotoplasCredit: true,
    seeMovementsTab: true,
    seeDevolution: true,
    seeCancelations: true,
    canCancelOrder: true,
    canReturnOrder: true,
    seeUserManagement: true,
    createUsers: true,
    haveBranches: true,
    seeShoppingLists: true,
    createShoppingLists: true,
    seeShoppingListsDetails: true,
    createShoppingListDetails: true,
    seeComunicados: true,
    seePLP: true,
    seeEcommerceOrders: false,
    seeConfig: true,
    seeIncidences: true,
    seeTransfer: true,
    seeCredit: true,
    seeCreditRotoplas: true
  },
  'Super Usuario asociado': {
    seeLogin: true,
    seeDashboard: true,
    seeOrders: true,
    createOrders: true,
    completeOrders: true,
    seeTransactionHistory: true,
    seeRotoplasCredit: true,
    seeMovementsTab: true,
    seeDevolution: true,
    seeConfig: true,
    seeIncidences: true,
    seeCancelations: true,
    canCancelOrder: true,
    canReturnOrder: true,
    seeUserManagement: true,
    createUsers: true,
    haveBranches: true,
    seeShoppingLists: true,
    createShoppingLists: true,
    seeShoppingListsDetails: true,
    createShoppingListDetails: true,
    seeComunicados: true,
    seePLP: true,
    seeEcommerceOrders: true,
    seeTransfer: true,
    seeCredit: true,
    seeCreditRotoplas: true
  },
  adminR: {
    seeLogin: true,
    seeDashboard: true,
    seeOrders: true,
    createOrders: true,
    completeOrders: true,
    seeTransactionHistory: true,
    seeRotoplasCredit: true,
    seeMovementsTab: true,
    seeDevolution: true,
    canCancelOrder: true,
    seeConfig: true,
    seeIncidences: true,
    canReturnOrder: true,
    seeCancelations: true,
    seeUserManagement: false,
    createUsers: false,
    haveBranches: true,
    seeShoppingLists: true,
    createShoppingLists: true,
    seeShoppingListsDetails: true,
    createShoppingListDetails: true,
    seeComunicados: true,
    seePLP: true,
    seeEcommerceOrders: false,
    seeTransfer: true,
    seeCredit: true,
    seeCreditRotoplas: true
  },
  PDVOperatorR: {
    seeLogin: true,
    seeDashboard: true,
    seeOrders: true,
    createOrders: false,
    completeOrders: false,
    seeConfig: true,
    seeIncidences: true,
    seeTransactionHistory: false,
    seeRotoplasCredit: false,
    seeMovementsTab: false,
    seeDevolution: true,
    seeCancelations: true,
    canCancelOrder: false,
    canReturnOrder: false,
    seeUserManagement: false,
    createUsers: false,
    haveBranches: false,
    seeShoppingLists: false,
    createShoppingLists: false,
    seeShoppingListsDetails: false,
    createShoppingListDetails: false,
    seeComunicados: false,
    seePLP: false,
    seeEcommerceOrders: false,
    seeTransfer: true,
    seeCredit: true,
    seeCreditRotoplas: true
  },
  PDVOperatorRA: {
    seeLogin: true,
    seeDashboard: true,
    seeOrders: true,
    createOrders: false,
    completeOrders: false,
    seeTransactionHistory: false,
    seeRotoplasCredit: false,
    seeMovementsTab: false,
    seeDevolution: true,
    seeCancelations: true,
    canCancelOrder: false,
    canReturnOrder: false,
    seeConfig: true,
    seeIncidences: true,
    seeUserManagement: false,
    createUsers: false,
    haveBranches: false,
    seeShoppingLists: false,
    createShoppingLists: false,
    seeShoppingListsDetails: false,
    createShoppingListDetails: false,
    seeComunicados: false,
    seePLP: false,
    seeEcommerceOrders: true,
    seeTransfer: true,
    seeCredit: true,
    seeCreditRotoplas: true
  },
  customerR: {
    seeLogin: true,
    seeDashboard: true,
    seeOrders: true,
    createOrders: true,
    completeOrders: true,
    seeTransactionHistory: true,
    seeRotoplasCredit: false,
    seeMovementsTab: true,
    seeDevolution: true,
    seeCancelations: true,
    seeConfig: true,
    seeIncidences: true,
    canCancelOrder: false,
    canReturnOrder: false,
    seeUserManagement: false,
    createUsers: false,
    haveBranches: true,
    seeShoppingLists: true,
    createShoppingLists: true,
    seeShoppingListsDetails: true,
    createShoppingListDetails: true,
    seeComunicados: true,
    seePLP: true,
    seeEcommerceOrders: false,
    seeTransfer: true,
    seeCredit: true,
    seeCreditRotoplas: true
  },
  customerRA: {
    seeLogin: true,
    seeDashboard: true,
    seeOrders: true,
    createOrders: true,
    completeOrders: true,
    seeTransactionHistory: true,
    seeConfig: true,
    seeIncidences: true,
    seeRotoplasCredit: false,
    seeMovementsTab: true,
    seeDevolution: true,
    seeCancelations: true,
    canCancelOrder: false,
    canReturnOrder: false,
    seeUserManagement: false,
    createUsers: false,
    haveBranches: true,
    seeShoppingLists: true,
    createShoppingLists: true,
    seeShoppingListsDetails: true,
    createShoppingListDetails: true,
    seeComunicados: true,
    seePLP: true,
    seeEcommerceOrders: true,
    seeTransfer: true,
    seeCredit: true,
    seeCreditRotoplas: true
  },
  AliadoVABR: {
    seeLogin: true,
    seeDashboard: true,
    seeOrders: true,
    seeConfig: true,
    seeIncidences: true,
    createOrders: true,
    completeOrders: true,
    seeTransactionHistory: true,
    seeRotoplasCredit: false,
    seeMovementsTab: true,
    seeDevolution: true,
    seeCancelations: true,
    canCancelOrder: true,
    canReturnOrder: true,
    seeUserManagement: true,
    createUsers: true,
    haveBranches: true,
    seeShoppingLists: true,
    createShoppingLists: true,
    seeShoppingListsDetails: true,
    createShoppingListDetails: true,
    seeComunicados: true,
    seePLP: true,
    seeEcommerceOrders: false,
    seeTransfer: true,
    seeCredit: true,
    seeCreditRotoplas: true
  },
  'agente-comprador': {
    seeLogin: true,
    seeDashboard: true,
    seeOrders: true,
    createOrders: true,
    completeOrders: true,
    seeTransactionHistory: false,
    seeRotoplasCredit: false,
    seeConfig: false,
    seeIncidences: false,
    seeMovementsTab: false, //Ver o ocultar Movimientos 
    seeDevolution: false,
    seeCancelations: false,
    canCancelOrder: false,
    canReturnOrder: false,
    seeUserManagement: false,
    createUsers: false,
    haveBranches: true,
    seeShoppingLists: true,
    createShoppingLists: true,
    seeShoppingListsDetails: true,
    createShoppingListDetails: true,
    seeComunicados: true,
    seePLP: true,
    seeEcommerceOrders: false, //Ver o ocultar mis ventas ecommercer
    seeTransfer: false,
    seeCredit: false,
    seeCreditRotoplas: true
  },
  'agente-no-comprador': {
    seeLogin: true,
    seeDashboard: true,
    seeOrders: true,
    createOrders: true,
    completeOrders: false,
    seeTransactionHistory: true,
    seeRotoplasCredit: true,
    seeMovementsTab: true,
    seeDevolution: true,
    seeCancelations: true,
    canCancelOrder: true,
    canReturnOrder: true,
    seeUserManagement: false,
    createUsers: true,
    haveBranches: true,
    seeShoppingLists: true,
    createShoppingLists: true,
    seeShoppingListsDetails: true,
    createShoppingListDetails: true,
    seeComunicados: true,
    seePLP: true,
    seeEcommerceOrders: false,
    seeConfig: true,
    seeIncidences: true,
    seeTransfer: true,
    seeCredit: true,
    seeCreditRotoplas: true
  },
  'agente': {
    seeLogin: true,
    seeDashboard: true,
    seeOrders: true,
    createOrders: true,
    completeOrders: true,
    seeTransactionHistory: true,
    seeRotoplasCredit: true,
    seeMovementsTab: true,
    seeDevolution: true,
    seeCancelations: true,
    canCancelOrder: true,
    canReturnOrder: true,
    seeUserManagement: true,
    createUsers: true,
    haveBranches: true,
    seeShoppingLists: true,
    createShoppingLists: true,
    seeShoppingListsDetails: true,
    createShoppingListDetails: true,
    seeComunicados: true,
    seePLP: true,
    seeEcommerceOrders: true,
    seeConfig: true,
    seeIncidences: true,
    seeTransfer: true,
    seeCredit: true,
    seeCreditRotoplas: true
  },
};
